import React, { useEffect, useState } from "react";
import Layout from "./component/layout/layout";
import Context from "./loginContext";
import { jwtDecode } from "jwt-decode";

if (window.location.search.split("=")[0] === "?token") {
  sessionStorage.setItem("token", window.location.search.split("=")[1]);
} else if (!sessionStorage.getItem("token")) {
  window.location.replace("https://vintageguarantee.com");
}

function App() {
// test
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ZGVhYTY5MTFmMjc4NzEzMTBhMmYiLCJyZWdfZGF0ZSI6Ik5vdiAzLCAyMDIzIDY6NTAgQU0iLCJiYWxhbmNlIjowLCJydW5uaW5nIjpbXSwicnVubmluZ19kZXBvc2l0IjowLCJydW5uaW5nX2JhbGFuY2UiOjAsInRvdGFsX2RlcG9zaXQiOjAsImFjdGl2ZV9kZXBvc2l0IjowLCJ0b3RhbF9lYXJuaW5nIjowLCJ0b3RhbF93aXRoZHJhd2FsIjowLCJ0b3RhbF9ib251cyI6MCwidG90YWxfcGVuYWx0eSI6MCwicGVuZGluZ193aXRoZHJhd2FsIjowLCJwZW5kaW5nX2RlcG9zaXQiOjAsInJlZmVycmFsX2NvbW1pc3Npb24iOjAsImFjdGl2ZSI6ZmFsc2UsInN1c3BlbmRlZCI6ZmFsc2UsImRpc2FibGVkIjpmYWxzZSwidXNlcm5hbWUiOiJ0ZXN0IiwiZW1haWwiOiJ0ZXN0QHRlc3QudGVzdCIsImZ1bGxfbmFtZSI6InRlc3RlciB0ZXN0IiwicGFzc3dvcmQiOnsicmF3IjoidGVzdCIsImhhc2giOiIkMmEkMTIkLkZnczR4aGtGVklqeVdOLmplNi9vT2Z0RTMxT3hWa0hadGFDM1R3SzNnVEF0dW1WTGtGTDYifSwidXBsaW5lIjoic3lzdGVtIHNpdGUiLCJyZWZlcnJhbCI6MCwid2l0aGRyYXdhbF9kZXRhaWxzIjpbeyJ0eXBlIjoiYml0Y29pbiIsInZhbHVlIjoieWhndGRkdHJ5eXV0dHJ1NXJ1cnJ1NTR1aTZpYnlpNnQ2IiwiYWRkcmVzcyI6IiJ9LHsidHlwZSI6ImV0aGVyZXVtIiwidmFsdWUiOiIiLCJhZGRyZXNzIjoiIn0seyJ0eXBlIjoidXNkdCIsInZhbHVlIjoiaXVieXR0aTg3aTc1aTY4NWk2NWk2aTZ0dXlydXI2IiwiYWRkcmVzcyI6IiJ9XSwiY3JlYXRlZEF0IjoiMjAyMy0xMS0wM1QxMTo1MTowNi4yNDVaIiwidXBkYXRlZEF0IjoiMjAyMy0xMS0wM1QxMTo1MTowNi4yNDVaIiwiX192IjowLCJpYXQiOjE2OTkwMTM5MDh9.rR9P0o_XZEhIIvMLLcnRvFeN9OQ7kHe0uJCmGhMAXJ0

  // ben
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTUwZTRhNTUxNzUyZWZlYmU3ZTJjMjQiLCJyZWdfZGF0ZSI6Ik5vdiAxMiwgMjAyMyA4OjQzIEFNIiwiYmFsYW5jZSI6MCwicnVubmluZyI6W10sInJ1bm5pbmdfZGVwb3NpdCI6MCwicnVubmluZ19iYWxhbmNlIjowLCJ0b3RhbF9kZXBvc2l0IjowLCJhY3RpdmVfZGVwb3NpdCI6MCwidG90YWxfZWFybmluZyI6MCwidG90YWxfd2l0aGRyYXdhbCI6MCwidG90YWxfYm9udXMiOjAsInRvdGFsX3BlbmFsdHkiOjAsInBlbmRpbmdfd2l0aGRyYXdhbCI6MCwicGVuZGluZ19kZXBvc2l0IjowLCJyZWZlcnJhbF9jb21taXNzaW9uIjowLCJhY3RpdmUiOmZhbHNlLCJzdXNwZW5kZWQiOmZhbHNlLCJkaXNhYmxlZCI6ZmFsc2UsInVzZXJuYW1lIjoiYmVuIiwiZW1haWwiOiJiZW50cmFkZTQ3MEBnbWFpbC5jb20iLCJmdWxsX25hbWUiOiJiZW4iLCJwYXNzd29yZCI6bnVsbCwidXBsaW5lIjoianVjb2FsbGlzb24iLCJyZWZlcnJhbCI6MCwid2l0aGRyYXdhbF9kZXRhaWxzIjpbeyJ0eXBlIjoiYml0Y29pbiIsInZhbHVlIjoieWhndGRkdHJ5eXV0dHJ1NXJ1cnJ1NTR1aTZpYnlpNnQ2IiwiYWRkcmVzcyI6IiJ9LHsidHlwZSI6ImV0aGVyZXVtIiwidmFsdWUiOiIiLCJhZGRyZXNzIjoiIn0seyJ0eXBlIjoidXNkdCIsInZhbHVlIjoiaXVieXR0aTg3aTc1aTY4NWk2NWk2aTZ0dXlydXI2IiwiYWRkcmVzcyI6IiJ9XSwiY3JlYXRlZEF0IjoiMjAyMy0xMS0xMlQxNDo0Mzo0OS4zMDFaIiwidXBkYXRlZEF0IjoiMjAyMy0xMS0xMlQxNDo0Mzo0OS4zMDFaIiwiX192IjowLCJpYXQiOjE2OTk4NDE3NTl9.7roH926Ayccx8e9_kYS03bnLcF90OlqbprIR8FOQUow

  // isiah
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTUwZTJlNDM0ZDczMjM2MzJhN2ExOGEiLCJyZWdfZGF0ZSI6Ik5vdiAxMiwgMjAyMyA4OjM0IEFNIiwiYmFsYW5jZSI6MCwicnVubmluZyI6W10sInJ1bm5pbmdfZGVwb3NpdCI6MCwicnVubmluZ19iYWxhbmNlIjowLCJ0b3RhbF9kZXBvc2l0IjowLCJhY3RpdmVfZGVwb3NpdCI6MCwidG90YWxfZWFybmluZyI6MCwidG90YWxfd2l0aGRyYXdhbCI6MCwidG90YWxfYm9udXMiOjAsInRvdGFsX3BlbmFsdHkiOjAsInBlbmRpbmdfd2l0aGRyYXdhbCI6MCwicGVuZGluZ19kZXBvc2l0IjowLCJyZWZlcnJhbF9jb21taXNzaW9uIjowLCJhY3RpdmUiOmZhbHNlLCJzdXNwZW5kZWQiOmZhbHNlLCJkaXNhYmxlZCI6ZmFsc2UsInVzZXJuYW1lIjoiaXNpYWgiLCJlbWFpbCI6ImlzaWFoY2FwaXRhbDQ3MEBnbWFpbC5jb20iLCJmdWxsX25hbWUiOiJpc2lhaCBjYXBpdGFsIiwicGFzc3dvcmQiOm51bGwsInVwbGluZSI6Imp1Y29hbGxpc29uIiwicmVmZXJyYWwiOjAsIndpdGhkcmF3YWxfZGV0YWlscyI6W3sidHlwZSI6ImJpdGNvaW4iLCJ2YWx1ZSI6InloZ3RkZHRyeXl1dHRydTVydXJydTU0dWk2aWJ5aTZ0NiIsImFkZHJlc3MiOiIifSx7InR5cGUiOiJldGhlcmV1bSIsInZhbHVlIjoiIiwiYWRkcmVzcyI6IiJ9LHsidHlwZSI6InVzZHQiLCJ2YWx1ZSI6Iml1Ynl0dGk4N2k3NWk2ODVpNjVpNmk2dHV5cnVyNiIsImFkZHJlc3MiOiIifV0sImNyZWF0ZWRBdCI6IjIwMjMtMTEtMTJUMTQ6MzY6MjAuNzYyWiIsInVwZGF0ZWRBdCI6IjIwMjMtMTEtMTJUMTQ6MzY6MjAuNzYyWiIsIl9fdiI6MCwiaWF0IjoxNjk5ODQxODMxfQ.lHiyZYg6Rj2jPQSRgWzkor4Uf1JIjMfR0VolkRrVoA0

  // jucoallison
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTUwZDlmYjU3NDljMTAwYTIwMmRlOTgiLCJyZWdfZGF0ZSI6Ik5vdiAxMiwgMjAyMyA3OjU2IEFNIiwiYmFsYW5jZSI6MCwicnVubmluZyI6W10sInJ1bm5pbmdfZGVwb3NpdCI6MCwicnVubmluZ19iYWxhbmNlIjowLCJ0b3RhbF9kZXBvc2l0IjowLCJhY3RpdmVfZGVwb3NpdCI6MCwidG90YWxfZWFybmluZyI6MCwidG90YWxfd2l0aGRyYXdhbCI6MCwidG90YWxfYm9udXMiOjAsInRvdGFsX3BlbmFsdHkiOjAsInBlbmRpbmdfd2l0aGRyYXdhbCI6MCwicGVuZGluZ19kZXBvc2l0IjowLCJyZWZlcnJhbF9jb21taXNzaW9uIjowLCJhY3RpdmUiOmZhbHNlLCJzdXNwZW5kZWQiOmZhbHNlLCJkaXNhYmxlZCI6ZmFsc2UsInVzZXJuYW1lIjoianVjb2FsbGlzb24iLCJlbWFpbCI6Imp1Y29hbGxpc29uQGdtYWlsLmNvbSIsImZ1bGxfbmFtZSI6Imp1Y28gYWxsaXNvbiIsInBhc3N3b3JkIjpudWxsLCJ1cGxpbmUiOiJzeXN0ZW0gc2l0ZSIsInJlZmVycmFsIjoyLCJ3aXRoZHJhd2FsX2RldGFpbHMiOlt7InR5cGUiOiJiaXRjb2luIiwidmFsdWUiOiJ5aGd0ZGR0cnl5dXR0cnU1cnVycnU1NHVpNmlieWk2dDYiLCJhZGRyZXNzIjoiIn0seyJ0eXBlIjoiZXRoZXJldW0iLCJ2YWx1ZSI6IiIsImFkZHJlc3MiOiIifSx7InR5cGUiOiJ1c2R0IiwidmFsdWUiOiJpdWJ5dHRpODdpNzVpNjg1aTY1aTZpNnR1eXJ1cjYiLCJhZGRyZXNzIjoiIn1dLCJjcmVhdGVkQXQiOiIyMDIzLTExLTEyVDEzOjU4OjE5LjYwMloiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTEyVDE0OjQzOjQxLjkxM1oiLCJfX3YiOjAsImlhdCI6MTY5OTg0MTg2Nn0.SQ8DNTShda1CGZn8uogjibl3B-md8FVX0ER3LA_Hkpg

  const [decoded, setDecoded] = useState({});
  const [data, setData] = useState([]);
  // const url = "http://localhost:5005/";
  const url = "https://server.vintageguarantee.com/";

  const setDateHandler = (payload) => {
    setData(payload);
    var decoded = jwtDecode(payload);

    return setDecoded(decoded);
  };

  return (
    <Context.Provider
      value={{
        url: url,
        data: data,
        setDate: setDateHandler,
        decoded,
      }}
    >
      <div id="google_translate_element"></div>
      <Layout />
    </Context.Provider>
  );
}

export default App;

// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/
// https://cryptocashletter.com/


// 353858
// 0073ff
// 047bf8



// real estate plan 10000 to 200000 10%

// savings plan 5000 to 100000  15%